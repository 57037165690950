.pageContainer {
    height: 100%;
    display: flex;
    justify-content: center;

    .mainContainer {
        width: 1200px;
        font-size: 20px;

        .banner {
            width: 100%;
            position: relative;

            .slogan {
                position: absolute;
                width: 100%;
                height: 480px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 32px;
                color: #FFFFFF;
            }
        }

        p {
            font-size: 16px;
        }
    }
}