.pageContainer {
    height: 100%;
    display: flex;
    justify-content: center;

    .mainContainer {
        width: 1200px;
        margin-top: 32px;

        .banner {
            position: relative;

            .slogan {
                position: absolute;
                width: 100%;
                height: 380px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 32px;
                color: #FFFFFF;
            }

            .img {
                width: 100%;
                height: 380px;
                margin-bottom: 24px;
            }
        }

        .icon {
            font-size: 16px;
            margin-right: 8px;
        }

        .zhqy {
            width: 160px;
        }
    }
}