.pageContainer {
    background-image: url(../../assets/img/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    animation-name: bgSwitch;
    animation-duration: 10s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    display: flex;
    align-content: center;
    flex-wrap: wrap;

    .slogan {
        color: #FFFFFF;
        font-size: 48px;
        display: flex;
        justify-content: center;
        animation-name: sloganDiscoloration;
        animation-duration: 5s;
        animation-delay: 0.5s;
        animation-iteration-count: infinite;
        width: 100%;
    }

    .secondarySlogan {
        color: #FFFFFF;
        font-size: 24px;
        display: flex;
        justify-content: center;
        animation-name: sloganDiscoloration;
        animation-duration: 5s;
        animation-delay: 0.5s;
        animation-iteration-count: infinite;
        width: 100%;
    }
}

// 标语变色
@keyframes bgSwitch {
    0% {
        background-image: url(../../assets/img/bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }

    100% {
        background-image: url(../../assets/img/bg2.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }
}

// 标语变色
@keyframes sloganDiscoloration {
    0% {
        color: #FFFFFF;
    }

    50% {
        color: #DE5F0E;
    }

    100% {
        color: #FFFFFF;
    }
}