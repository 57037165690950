.pageContainer {
    height: 100%;
    display: flex;
    justify-content: center;

    .mainContainer {
        width: 1200px;
        margin-top: 32px;

        .zhglpt {
            background-image: linear-gradient(to right, #ebe717, #7ec6bc);
            height: 160px;
        }
    }
}