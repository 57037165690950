.pageContainer {
    height: 100%;

    .headerContainer {
        height: 48px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: flex-start;

        .logoContainer {
            width: 400px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;

            .logo {
                height: 32px;
                cursor: pointer;
            }
        }

        .navigateContainer {
            display: flex;
            align-items: center;

            a {
                color: #000000;
            }

            .active {
                color: #DE5F0E;
            }
        }
    }

    .contentContainer {
        height: calc(100% - 96px);
        background-color: #F5F5F5;
        overflow: auto;
    }

    .footerContainer {
        height: 48px;
        background-color: #001529;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;

        a {
            color: #FFFFFF;
        }
    }
}