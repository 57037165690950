html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

ul {
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

ul>li {
    display: inline;
    list-style-type: none;
    padding-right: 32px;
}

ul>li:last-child {
    padding-right: 0px;
}

ul>li>a {
    text-decoration: none;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}